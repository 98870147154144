<template>
  <div class="guest-appoint">
    <div class="guest-appoint-main">
      <park-header></park-header>
      <div class="guest-appoint-content">
        <go-back-title :title="'访客预约'"></go-back-title>
        <!--<van-form @submit="onSubmit">-->
          <!--<plate-number :required=true :value.sync="form.plateNumber"></plate-number>-->
          <!--<vh-cate :required=true :value.sync="form.vhCateId"></vh-cate>-->
          <!--<vh-type :required=true :value.sync="form.vhTypeId"></vh-type>-->
          <!--<div class="expired-wrap">-->
            <!--<div class="expired-title">请选择注册车辆有效期</div>-->
            <!--<div class="expired-bnt-wrap">-->
              <!--<div :class="['expire-item', form.forever ? 'active' : '']" @click="changeForever">永久有效</div>-->
              <!--<div :class="['expire-item', !form.forever ? 'active' : '']" @click="changeForever">短期有效</div>-->
            <!--</div>-->
          <!--</div>-->
          <!--<template v-if="!form.forever">-->
            <!--<van-cell value="按月充值(月)">-->
              <!--<template #right-icon>-->
                <!--<van-stepper v-model="form.num" theme="round" button-size="22"-->
                             <!--default-value=1-->
                             <!--min=1-->
                             <!--@change="getExpireTimeRange"-->
                             <!--disable-input />-->
              <!--</template>-->
            <!--</van-cell>-->
            <!--<van-field label="有效时间" name="expireTimeRange" is-link-->
                       <!--readonly v-model="form.expireTimeRange"-->
                       <!--:rules="[]"-->
                       <!--placeholder="" />-->
          <!--</template>-->
          <!--<van-cell value="更多信息录入" @click.native="showMore">-->
            <!--<template #extra>-->
              <!--<div :class="['more-img', isMore ? 'up' : 'down']"></div>-->
            <!--</template>-->
          <!--</van-cell>-->
          <!--<template v-if="isMore">-->
            <!--<van-field :rules="[]" name="monthlyRentFee" label="月租金" v-model="form.monthlyRentFee" placeholder="" />-->
            <!--<van-field :rules="[]" name="guaranteeDepositFee" label="押金" v-model="form.guaranteeDepositFee" placeholder="" />-->
            <!--<van-field :rules="[]" name="ownerName" label="车主姓名" v-model="form.ownerName" placeholder="" />-->
            <!--<van-field :rules="[]" name="ownerPhone" label="联系方式" v-model="form.ownerPhone" placeholder="" />-->
          <!--</template>-->
          <!--<div style="margin: 16px;">-->
            <!--<van-button round block type="info" native-type="submit">提交</van-button>-->
          <!--</div>-->
        <!--</van-form>-->
      </div>
    </div>
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'

import goBackTitle from '../../public/go-back-title'
import { mapState } from 'vuex'
export default {
  name: 'guest-appoint',
  components: {
    parkHeader,
    goBackTitle
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    // onMessage (message) {
    //   console.log('message', message)
    //   if (message && message.code == common.resCode.OK) {
    //     this.$nextTick(() => {
    //       this.form.vhCateId = 1
    //       this.form.vhTypeId = 1
    //       this.form.plateNumber = ''
    //       this.form.monthlyRentFee = 0
    //       this.form.ownerName = ''
    //       this.form.guaranteeDepositFee = 0
    //       this.form.ownerPhone = ''
    //     })
    //     this.$toast.success('添加成功')
    //   } else {
    //     this.$toast.fail('添加失败')
    //   }
    // },
    // async onSubmit (form) {
    //   try {
    //     console.log('form', form)
    //
    //     let para = {
    //       plate_number: form.plateNumber,
    //       vh_type_id: this.form.vhTypeId,
    //       vh_cate_id: this.form.vhCateId,
    //       // customer_id: localStorage.getItem("customer_id"),
    //       sid: localStorage.getItem('sid'),
    //       monthly_rent_fee: Number(form.monthlyRentFee)*100,
    //       owner_name: form.ownerName,
    //       guarantee_deposit_fee: Number(form.guaranteeDepositFee)*100,
    //       owner_phone: form.ownerPhone,
    //       ...api.getPublicPara('v2')
    //     }
    //     para.effective_date_start = this.startTime
    //     para.effective_date_end = this.endTime
    //     para.charged_month_count = this.form.num
    //     if (!para.access_token) {
    //       this.$router.push({
    //         path: '/login',
    //         query: {
    //           ...api.getQuery.apply(this)
    //         }
    //       })
    //       return
    //     }
    //     this.$toast.loading('正在请求中...')
    //     let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.add_vh}`;
    //     await mqtt.subscribe(topic)
    //     this.isLoading = true
    //     mqtt.Client.config[mqtt.topics.add_vh] = this.onMessage
    //     let res = await api.axiosRequest(api.urlList.fenglin.vh.add, para)
    //     this.$toast.clear()
    //     this.isLoading = false
    //     if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
    //   } catch (e) {
    //     common.showErrorMessage(this, e)
    //   }
    // },
  },

  mounted () {
    // new mqtt.Client()
    // this.getExpireTimeRange ()
  },
  beforeDestroy () {
    // let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.add_vh}`
    // mqtt.unsubscribe(topic)
    // delete mqtt.Client.config[mqtt.topics.add_vh]
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.guest-appoint {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .guest-appoint-content {
      box-sizing: border-box;
      margin: 1rem 1rem 0 1rem;
    }
  }
  /deep/ .van-stepper--round .van-stepper__minus {
    color: white;
    background-color:$global-theme-blue;
    border: .2rem solid $global-theme-blue;
  }
  /deep/ .van-stepper--round .van-stepper__plus {
    color: #fff;
    background-color: $global-theme-blue;
  }
}
</style>
